/*
 * Override select styles
 */

.Select-control {
  height: 44px;
  border-color: #ebebeb;
  font-size: 13px;
}

.Select.small .Select-control {
  font-size: 12px;
}

.toolbar-select .Select-control {
  height: 60px;
  border-left: 0;
  border-top: 0;
  border-bottom: 0;
  border-radius: 0;
  outline: none;
  border-color: #ebebeb !important;
  box-shadow: none !important;
}

.error .Select-control {
  border-color: #d0021b !important;
}

.Select-control:hover {
  box-shadow: none !important;
}

.Select-input {
  height: 44px;
  padding-left: 14px;
  padding-right: 50px;
  font-weight: 400;
  outline: none;
}

.Select-input:focus {
  outline: none;
}

.toolbar-select .Select-input {
  height: 60px;
  line-height: 60px;
  padding-left: 0;
}

.Select-input > input {
  height: 26px;
  font-weight: 400;
}

.Select-placeholder {
  line-height: 44px;
  padding-left: 14px;
  padding-right: 54px;
  font-weight: 400;
}

.toolbar-select .Select-placeholder {
  line-height: 60px;
  padding-left: 0;
}

.Select-arrow-zone {
  padding-top: 4px;
  width: 34px;
}

.filterBar {
  border-right: 1px solid #ebebeb;
  outline: none;
  z-index: 2;
}

.filterBar .Select-control {
  background: none;
  border: 0 none !important;
  cursor: pointer;
  outline: none;
  box-shadow: none !important;
}

.Select-menu-outer {
  font-size: 13px;
  outline: none !important;
  z-index: 6;
}

.Select--single .Select-value-label {
  display: block;
  padding-left: 6px;
  padding-top: 6px;
  overflow: hidden;
  max-width: 75%;
  text-overflow: ellipsis;
}

.Select--multi .Select-value-label {
  padding: 8px 10px;
}

.Select--multi .Select-value-icon {
  padding: 8px 5px;
}

.toolbar-select.Select--single > .Select-control .Select-value {
  right: 45px;
  line-height: 60px;
  padding-top: 0;
}
